import React, { useState, useEffect, useRef } from "react";

// Chakra imports
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    Text,
    useColorModeValue,
    Textarea,
    useToast,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { Query } from "helpers/query";
import Loading from "components/loading";
import { isImg } from "helpers";
import { isLogin } from "helpers";

export default function EditCategory(props) {
    const textColor = useColorModeValue("navy.700", "white");
    const toast = useToast();

    const brandStars = useColorModeValue("brand.500", "brand.400");
    const history = useHistory();

    const [loading, setLoading] = React.useState(true);
    const [data, handleData] = useState();

    const status = {
        isEdit: window.location.href.includes("edit"),
        id: props.match.params.CATEGORY_ID,
    };
    const { isEdit, id } = status;
    const titleInput = useRef();
    const imgInput = useRef();

    const dscInput = useRef();

    const getDetai = () => {
        Query(
            {
                url: `category/single/${id}`,
                method: "GET",
            },
            (response) => {
                setLoading(false);
                console.log(response.data.category);
                handleData(response.data.category);
            }
        );
    };

    const handleAdd = () => {
        const titleValue = titleInput.current.value;
        const imgValue = imgInput.current.value;
        const dscValue = dscInput.current.value;

        console.log(titleValue.length, isImg(imgValue));
        if (titleValue.length && isImg(imgValue)) {
            if (isEdit) {
                Query(
                    {
                        url: `superAdmin/category/update/${id}`,
                        method: "PATCH",
                        data: {
                            title: titleValue,
                            image: imgValue,
                            description: dscValue,
                        },
                    },
                    (response) => {
                        console.log(response);
                        if (response.success) {
                            toast({
                                title: "Category Edited.",
                                status: "success",
                                duration: 2000,
                                isClosable: true,
                                position: "top-right",
                            });

                            history.push("/admin/categories");
                        }
                    }
                );
            } else {
                Query(
                    {
                        url: `superAdmin/category/store`,
                        method: "POST",
                        data: {
                            title: titleValue,
                            image: imgValue,
                            description: dscValue,
                        },
                    },
                    (response) => {
                        console.log(response);
                        if (response.success) {
                            toast({
                                title: "Category Added.",
                                status: "success",
                                duration: 2000,
                                isClosable: true,
                                position: "top-right",
                            });

                            history.push("/admin/categories");
                        }
                    }
                );
            }
        } else {
            alert("please fill image and title filled");
        }
    };

    useEffect(() => {
        isEdit && getDetai();
    }, []);

    useEffect(() => {
        !isLogin() && history.push("/auth/sign-in");
    }, []);

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            {isEdit && loading ? (
                <Loading />
            ) : (
                <FormControl>
                    <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                    >
                        Title<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                        isRequired={true}
                        fontSize="sm"
                        ms={{ base: "0px", md: "0px" }}
                        type="text"
                        placeholder="title"
                        mb="24px"
                        fontWeight="500"
                        size="lg"
                        defaultValue={data && data.title && data.title}
                        variant="auth"
                        ref={titleInput}
                    />
                    <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                    >
                        Image<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size="md">
                        <Input
                            isRequired={true}
                            fontSize="sm"
                            placeholder="Image link"
                            mb="24px"
                            size="lg"
                            type="text"
                            defaultValue={data && data.image && data.image}
                            variant="auth"
                            ref={imgInput}
                        />
                    </InputGroup>

                    <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                    >
                        Description
                    </FormLabel>
                    <Textarea
                        fontSize="sm"
                        placeholder="Description..."
                        mb="24px"
                        size="lg"
                        type="textarea"
                        defaultValue={data && data.description && data.description}
                        ref={dscInput}
                    />

                    <Button
                        fontSize="sm"
                        variant="brand"
                        fontWeight="500"
                        mb="24px"
                        onClick={() => handleAdd()}
                    >
                        {isEdit ? "edit" : "Add"}
                    </Button>
                </FormControl>
            )}
        </Box>
    );
}
