import React from "react";
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalCloseButton,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/react";
import { Query } from "helpers/query";

export default function EditButtons({ id }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const handleDelete = () => {
        Query(
            {
                url: `superAdmin/post/delete/${id}`,
                method: "DELETE",
            },
            (response) => {
                console.log(response);
                window.location.reload();
            },
            (error) => {
                console.log(error);
            }
        );
    };
    return (
        <div>
            {" "}
            <Button me="14px" onClick={onOpen}>
                <DeleteIcon />
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Are you sure to delete this post?</ModalHeader>
                    <ModalCloseButton />

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleDelete}>
                            Yes
                        </Button>
                        <Button variant="ghost" onClick={onClose}>
                            {" "}
                            No
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}
