import React from "react";
// Chakra imports
import { Flex, Icon, Image, Text, useColorModeValue, Button } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
} from "@chakra-ui/react";
import { Query } from "helpers/query";

export default function NFT(props) {
    const { image, name, author, date, price, custom, id, repeatLoading, type } = props;
    const history = useHistory();
    // Chakra Color Mode
    const textColor = useColorModeValue("brands.900", "white");
    const bgItem = useColorModeValue(
        { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
        { bg: "navy.700", boxShadow: "unset" }
    );
    const textColorDate = useColorModeValue("secondaryGray.600", "white");
    const toast = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    console.log(type, "yo yoy hgohfb");
    const handleDelete = () => {
        Query(
            {
                url: `superAdmin/${type}/delete/${id}`,
                method: "DELETE",
            },
            (response) => {
                if (response.success) {
                    repeatLoading();
                    toast({
                        title: "Category Deleted.",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                        position: "top-right",
                    });
                }
                // setLoading(false);
                // console.log(response.data.category);
                // handleData(response.data.category);
            }
        );
    };

    return (
        <Card
            _hover={bgItem}
            bg="transparent"
            boxShadow="unset"
            px="24px"
            py="21px"
            transition="0.2s linear"
        >
            <Flex direction={{ base: "column" }} justify="center">
                <Flex position="relative" align="center">
                    <Text
                        color="secondaryGray.600"
                        fontSize={{
                            base: "sm",
                        }}
                        fontWeight="400"
                        me="14px"
                    >
                        {custom && custom()}
                    </Text>

                    {image && <Image src={image} w="66px" h="66px" borderRadius="20px" me="16px" />}

                    <Flex
                        direction="column"
                        w={{ base: "70%", md: "30%" }}
                        me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
                    >
                        <Text
                            color={textColor}
                            fontSize={{
                                base: "md",
                            }}
                            mb="5px"
                            fontWeight="bold"
                            me="14px"
                        >
                            {name}
                        </Text>
                        <Text
                            color="secondaryGray.600"
                            fontSize={{
                                base: "sm",
                            }}
                            fontWeight="400"
                            me="14px"
                        >
                            {author}
                        </Text>
                    </Flex>
                    <Flex
                        me={{ base: "16px", md: "32px", xl: "10px", "3xl": "32px" }}
                        align="center"
                    >
                        <Text
                            fontSize={{
                                base: "sm",
                            }}
                            fontWeight="400"
                            color={textColor}
                        >
                            {price}
                        </Text>
                    </Flex>
                    <Text ms="auto" fontWeight="400" fontSize="sm" me="14px" color={textColorDate}>
                        {date}
                    </Text>
                    <Button me="14px" onClick={() => history.push(`/admin/category/edit/${id}`)}>
                        <EditIcon />
                    </Button>

                    <Button>
                        <DeleteIcon onClick={onOpen} />
                    </Button>
                </Flex>
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Are you sure to delete this category?</ModalHeader>
                    <ModalCloseButton />

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleDelete}>
                            Yes
                        </Button>
                        <Button variant="ghost" onClick={onClose}>
                            {" "}
                            No
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Card>
    );
}
