import {
    Flex,
    Table,
    Progress,
    Icon,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Image,
    Button,
    Badge,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { useDisclosure } from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { Link } from "react-router-dom";
// Assets
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
import { ChangeDate } from "helpers";
import { useHistory } from "react-router-dom";
import { EditIcon, DeleteIcon, DownloadIcon, InfoIcon } from "@chakra-ui/icons";
import { HeartIcon } from "components/icons/Icons";
import { Query } from "helpers/query";
import EditButtons from "components/button/EditButtons";
export default function ColumnsTable(props) {
    const { columnsData, tableData, count, currrentPage } = props;
    const history = useHistory();

    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState } =
        tableInstance;
    initialState.pageSize = 21;

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    return (
        <Card direction="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "scroll" }}>
            <Flex px="25px" justify="space-between" mb="20px" align="center">
                <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
                    Total : {count}
                </Text>
                <Button me="0" variant="outline" onClick={() => history.push(`/admin/post/add`)}>
                    Add Post
                </Button>
            </Flex>
            <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
                <Thead>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                                <Th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    pe="10px"
                                    key={index}
                                    borderColor={borderColor}
                                >
                                    <Flex
                                        justify="space-between"
                                        align="center"
                                        fontSize={{ sm: "10px", lg: "12px" }}
                                        color="gray.400"
                                    >
                                        {column.render("Header")}
                                    </Flex>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                            <>
                                <Tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, index) => {
                                        let data = "";
                                        if (cell.column.Header === "TITLE") {
                                            data = (
                                                <Text
                                                    key={index}
                                                    color={textColor}
                                                    fontSize="sm"
                                                    fontWeight="700"
                                                >
                                                    {cell.value}
                                                </Text>
                                            );
                                        } else if (cell.column.Header === "STATUS") {
                                            data = (
                                                <Flex align="center" key={index}>
                                                    <Icon
                                                        w="24px"
                                                        h="24px"
                                                        me="5px"
                                                        color={
                                                            cell.value === "published"
                                                                ? "green.500"
                                                                : cell.value === "Disable"
                                                                ? "red.500"
                                                                : cell.value === "Error"
                                                                ? "orange.500"
                                                                : null
                                                        }
                                                        as={
                                                            cell.value === "published"
                                                                ? MdCheckCircle
                                                                : cell.value === "Disable"
                                                                ? MdCancel
                                                                : cell.value === "Error"
                                                                ? MdOutlineError
                                                                : null
                                                        }
                                                    />
                                                    <Text
                                                        color={textColor}
                                                        fontSize="sm"
                                                        fontWeight="700"
                                                    >
                                                        {cell.value}
                                                    </Text>
                                                </Flex>
                                            );
                                        } else if (cell.column.Header === "CREATEDAT") {
                                            data = (
                                                <Text
                                                    key={index}
                                                    color={textColor}
                                                    fontSize="sm"
                                                    fontWeight="700"
                                                >
                                                    {ChangeDate(cell.value)}
                                                </Text>
                                            );
                                        } else if (cell.column.Header === "PROGRESS") {
                                            data = (
                                                <Flex align="center" key={index}>
                                                    <Progress
                                                        variant="table"
                                                        colorScheme="brandScheme"
                                                        h="8px"
                                                        w="108px"
                                                        value={cell.value}
                                                    />
                                                </Flex>
                                            );
                                        } else if (cell.column.Header === "CATEGORY") {
                                            data = (
                                                <Text
                                                    key={index}
                                                    color={textColor}
                                                    fontSize="sm"
                                                    fontWeight="700"
                                                >
                                                    {cell.value.title}
                                                </Text>
                                            );
                                        } else if (cell.column.Header === "DESCRIPTION") {
                                            data = (
                                                <Text
                                                    key={index}
                                                    color={textColor}
                                                    fontSize="sm"
                                                    fontWeight="700"
                                                >
                                                    {cell.value}
                                                </Text>
                                            );
                                        } else if (
                                            cell.column.Header === "ORIGINALIMAGE" ||
                                            cell.column.Header === "COMPRESSEDIMAGE"
                                        ) {
                                            console.log(cell.row.index, currrentPage, "rowwwwww");
                                            data = (
                                                <div key={index}>
                                                    <Image
                                                        src={cell.value}
                                                        w="80px"
                                                        h="80px"
                                                        borderRadius="20px"
                                                        me="16px"
                                                    />
                                                    <div>
                                                        <Text
                                                            color={textColor}
                                                            fontSize="sm"
                                                            fontWeight="700"
                                                        >
                                                            <a href={cell.value} target="_blank">
                                                                link
                                                            </a>
                                                        </Text>
                                                    </div>
                                                </div>
                                            );
                                        } else if (cell.column.Header === "COUNT") {
                                            data = (
                                                <p>
                                                    {(currrentPage - 1) * 21 + (cell.row.index + 1)}
                                                </p>
                                            );
                                        } else if (cell.column.Header === "TAGS") {
                                            data = (
                                                <div key={index}>
                                                    {cell.value.map((item, i) => (
                                                        <Badge
                                                            key={i}
                                                            color={textColor}
                                                            fontSize="sm"
                                                            fontWeight="700"
                                                            mt={1}
                                                        >
                                                            {item.title}
                                                        </Badge>
                                                    ))}
                                                </div>
                                            );
                                        } else if (cell.column.Header === "EDIT") {
                                            const id = cell.row.original._id;
                                            data = (
                                                <Flex key={index}>
                                                    <Button
                                                        me="14px"
                                                        onClick={() =>
                                                            history.push(`/admin/post/edit/${id}`)
                                                        }
                                                    >
                                                        <EditIcon />
                                                    </Button>

                                                    <Button
                                                        me="14px"
                                                        onClick={() =>
                                                            history.push(`/admin/post/detail/${id}`)
                                                        }
                                                    >
                                                        <InfoIcon />
                                                    </Button>
                                                    <EditButtons id={id} />
                                                </Flex>
                                            );
                                        }
                                        return (
                                            <>
                                                <Td
                                                    {...cell.getCellProps()}
                                                    key={index}
                                                    fontSize={{ sm: "14px" }}
                                                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                                    borderColor="transparent"
                                                >
                                                    {data}
                                                </Td>
                                            </>
                                        );
                                    })}
                                </Tr>
                            </>
                        );
                    })}
                </Tbody>
            </Table>
        </Card>
    );
}
