import React, { useState, useRef, useEffect } from "react";
import { Button } from "@chakra-ui/react";
export default function MyButton({ item, click, detail, existed }) {
    const [variant, handleVariant] = useState(false);

    const inputName = useRef(null);

    const handleAddCategory = () => {
        handleVariant(!variant);
        click && click(item, variant);
    };
    useEffect(() => {
        let i = 0;

        existed.map((ex, i) => ex._id === item._id && handleVariant(true));
    }, []);
    return (
        <Button
            me={2}
            my={2}
            colorScheme="teal"
            variant={variant ? "solid" : "outline"}
            size="sm"
            onClick={() => handleAddCategory()}
            ref={inputName}
            disabled={detail}
        >
            {item.title}
        </Button>
    );
}
