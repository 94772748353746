import { useLocation } from "react-router";

import Cookies from "universal-cookie";

const cookies = new Cookies();

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const scrollToTop = () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
};

export const ChangeDate = (date) => {
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const _date = new Date(date);

    const monthName = monthNames[_date.getMonth()];
    const day = _date.getDate();
    const year = _date.getFullYear();

    const dateString = monthName + " " + day + " , " + year;

    return dateString;
};

export const isImg = (str) => {
    let regex = /(https?:\/\/.*\.(?:png|jpg|jpeg|JPG|PNG|JPEG))/;

    return regex.test(str);
};

export const isLogin = () =>
    !!cookies.get("x-access-token", {
        sameSite: "strict",
        path: "/",
        expires: new Date(new Date().getTime() + 60 * 1000 * 100),
    });

export const setToken = (token, id) => {
    cookies.set("x-access-token", token, {
        sameSite: "strict",
        path: "/",
        expires: new Date(new Date().getTime() + 60 * 1000 * 100),
    });

    cookies.set("id", id, {
        sameSite: "strict",
        path: "/",
        expires: new Date(new Date().getTime() + 60 * 1000 * 100),
    });
};
export const removeToken = () => {
    cookies.remove("x-access-token", {
        sameSite: "strict",
        path: "/",
        expires: new Date(new Date().getTime() + 60 * 1000 * 100),
    });
    cookies.remove("id", {
        sameSite: "strict",
        path: "/",
        expires: new Date(new Date().getTime() + 60 * 1000 * 100),
    });
};

export const getToken = () => {
    return cookies.get("x-access-token", {
        sameSite: "strict",
        path: "/",
        expires: new Date(new Date().getTime() + 60 * 1000 * 100),
    });
};

export const isEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};
