export const columnsDataDevelopment = [
    {
        Header: "count",
        accessor: "count",
    },
    {
        Header: "firstName",
        accessor: "firstName",
    },
    {
        Header: "lastName",
        accessor: "lastName",
    },
    {
        Header: "email",
        accessor: "email",
    },
    {
        Header: "type",
        accessor: "type",
    },
];

export const columnsDataCheck = [
    {
        Header: "NAME",
        accessor: "name",
    },
    {
        Header: "PROGRESS",
        accessor: "progress",
    },
    {
        Header: "QUANTITY",
        accessor: "quantity",
    },
    {
        Header: "DATE",
        accessor: "date",
    },
];

export const columnsDataColumns = [
    {
        Header: "NAME",
        accessor: "name",
    },
    {
        Header: "PROGRESS",
        accessor: "progress",
    },
    {
        Header: "QUANTITY",
        accessor: "quantity",
    },
    {
        Header: "DATE",
        accessor: "date",
    },
];

export const columnsDataComplex = [
    {
        Header: "COUNT",
        accessor: "count",
    },
    {
        Header: "ORIGINALIMAGE",
        accessor: "originalImage",
    },
    {
        Header: "COMPRESSEDIMAGE",
        accessor: "compressedImage",
    },
    {
        Header: "TITLE",
        accessor: "title",
    },

    {
        Header: "CREATEDAT",
        accessor: "createdAt",
    },
    {
        Header: "CATEGORY",
        accessor: "category",
    },
    {
        Header: "DESCRIPTION",
        accessor: "description",
    },

    {
        Header: "TAGS",
        accessor: "tags",
    },
    {
        Header: "STATUS",
        accessor: "status",
    },
    {
        Header: "EDIT",
        accessor: "edit",
    },
];
