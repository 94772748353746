import React, { useState, useEffect, useRef } from "react";

// Chakra imports
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    Text,
    useColorModeValue,
    useColorMode,
    Textarea,
    useToast,
    Tag,
    TagLabel,
    TagLeftIcon,
    TagRightIcon,
    Switch,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { Query } from "helpers/query";
import Loading from "components/loading";
import { isImg } from "helpers";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import MyButton from "components/button";
import { isLogin } from "helpers";

export default function EditPost(props) {
    const textColor = useColorModeValue("navy.700", "white");
    const toast = useToast();

    const brandStars = useColorModeValue("brand.500", "brand.400");
    let inputBg = useColorModeValue("navy.700", "white");
    let navbarPosition = "absolute";

    const history = useHistory();
    const { colorMode } = useColorMode();

    const [loading, setLoading] = useState(false);

    const [tagLoading, setTagLoading] = useState(false);
    const [categoryLoading, setCategoryLoading] = useState(false);

    const [data, handleData] = useState();
    const [tags, handleTags] = useState([]);

    // const [selectedCategory, handleSelectedCategory] = useState([]);
    const [existedTags, handleExistedTags] = useState([]);
    const [existedCategories, handleExistedCategories] = useState([]);
    // const [selectedTag, handleSelectedTag] = useState([]);

    const [categories, handleCategories] = useState([]);

    const status = {
        isEdit: window.location.href.includes("edit"),
        id: props.match.params.POST_ID,
        isDetail: window.location.href.includes("detail"),
        isAdd: window.location.href.includes("add"),
    };

    const { isEdit, id, isDetail, isAdd } = status;
    const titleInput = useRef();
    const originalImgInput = useRef();
    const compImgInput = useRef();

    const dscInput = useRef();

    const privateInput = useRef();

    const getDetai = () => {
        setLoading(true);
        let arr = [];
        Query(
            {
                url: `superAdmin/post/single/${id}`,
                method: "GET",
            },
            (response) => {
                console.log(response.data, "resss");
                handleData(response.data);
                handleExistedTags(response.data.tags);

                arr.push(response.data.category);
                handleExistedCategories(arr);
                setLoading(false);
            }
        );
    };

    const getTags = () => {
        setTagLoading(true);
        Query(
            {
                url: "tag/index?all=true",
                method: "GET",
            },
            (response) => {
                handleTags(response.data.items);
                setTagLoading(false);
            }
        );
    };

    const getCategories = () => {
        setCategoryLoading(true);
        Query(
            {
                url: "category/index",
                method: "GET",
            },
            (response) => {
                handleCategories(response.data.items);
                setCategoryLoading(false);
            }
        );
    };
    const addSelectedCategories = (item, variant) => {
        if (variant) {
            handleExistedCategories(
                existedCategories.filter((_category) => _category._id !== item._id)
            );
        } else {
            handleExistedCategories([item, ...existedCategories]);
        }
    };

    const addSelectedTags = (item, variant) => {
        if (variant) {
            handleExistedTags(existedTags.filter((_tag) => _tag._id !== item._id));
        } else {
            handleExistedTags([item, ...existedTags]);
        }
    };

    const handleAdd = () => {
        const titleValue = titleInput.current.value;
        const orgImgValue = originalImgInput.current.value;
        const compImgValue = compImgInput.current.value;
        const descriptionValue = dscInput.current.value;

        var categoryId = existedCategories.map((obj) => {
            return obj._id;
        });

        var tagsId = existedTags.map((item) => {
            return item._id;
        });

        console.log(isImg(compImgValue), isImg(orgImgValue), titleValue, "yooo");

        if (titleValue.length && isImg(compImgValue) && isImg(orgImgValue)) {
            if (isAdd) {
                Query(
                    {
                        url: "superAdmin/post/store",
                        method: "POST",
                        data: {
                            title: titleValue,
                            categoryId: categoryId[0],
                            tagsId: JSON.stringify(tagsId),
                            description: descriptionValue,
                            originalImage: orgImgValue,
                            compressedImage: compImgValue,
                            license: "false",
                        },
                    },
                    (response) => {
                        console.log(response);
                        if (response.success) {
                            toast({
                                title: "Post Added.",
                                status: "success",
                                duration: 2000,
                                isClosable: true,
                                position: "top-right",
                            });
                            history.push("/admin/posts");
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            } else if (isEdit) {
                console.log("yo ediit", existedTags);
                const checked = privateInput.current.checked;
                let _status = checked ? "private" : "published";

                Query(
                    {
                        url: `superAdmin/post/update/${id}`,
                        method: "PATCH",
                        data: {
                            title: titleValue,
                            categoryId: categoryId[0],
                            tagsId: JSON.stringify(tagsId),
                            description: descriptionValue,
                            originalImage: orgImgValue,
                            compressedImage: compImgValue,
                            license: "false",
                            status: _status,
                        },
                    },
                    (response) => {
                        console.log(response);
                        if (response.success) {
                            toast({
                                title: "Post Edited.",
                                status: "success",
                                duration: 2000,
                                isClosable: true,
                                position: "top-right",
                            });
                            history.push("/admin/posts");
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        } else {
            toast({
                title: "Title,compressedImage,originalImage fields are required. ",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
        }
    };

    useEffect(() => {
        getTags();
        getCategories();
    }, []);

    useEffect(() => {
        (isEdit || isDetail) && getDetai();
    }, []);
    useEffect(() => {
        !isLogin() && history.push("/auth/sign-in");
    }, []);

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            {(isEdit || isDetail) && loading ? (
                <Loading />
            ) : (
                <FormControl>
                    <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="md"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                    >
                        Title<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                        isRequired={true}
                        fontSize="sm"
                        ms={{ base: "0px", md: "0px" }}
                        type="text"
                        placeholder="title"
                        mb="24px"
                        fontWeight="500"
                        size="lg"
                        defaultValue={data && data.title && data.title}
                        variant="auth"
                        ref={titleInput}
                        disabled={isDetail}
                    />
                    <FormLabel
                        ms="4px"
                        fontSize="md"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                    >
                        compressedImage<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size="md">
                        <Input
                            isRequired={true}
                            fontSize="sm"
                            placeholder="compressedImage"
                            mb="24px"
                            size="lg"
                            type="text"
                            defaultValue={data && data.compressedImage && data.compressedImage}
                            variant="auth"
                            ref={compImgInput}
                            disabled={isDetail}
                        />
                    </InputGroup>

                    <FormLabel
                        ms="4px"
                        fontSize="md"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                    >
                        originalImage<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size="md">
                        <Input
                            isRequired={true}
                            fontSize="sm"
                            placeholder="originalImage"
                            mb="24px"
                            size="lg"
                            type="text"
                            defaultValue={data && data.originalImage && data.originalImage}
                            variant="auth"
                            ref={originalImgInput}
                            disabled={isDetail}
                        />
                    </InputGroup>

                    <FormLabel
                        ms="4px"
                        fontSize="md"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                    >
                        Description
                    </FormLabel>
                    <Textarea
                        fontSize="md"
                        placeholder="Description..."
                        mb="24px"
                        size="lg"
                        type="textarea"
                        defaultValue={data && data.description && data.description}
                        ref={dscInput}
                        disabled={isDetail}
                    />
                    <Box display="flex" alignItems="center" mb="24px">
                        <FormLabel htmlFor="email-alerts" mb="0">
                            Private?
                        </FormLabel>
                        <Switch
                            id="email-alerts"
                            defaultChecked={data && data.status === "private" ? true : false}
                            ref={privateInput}
                            isDisabled={isDetail}
                        />
                    </Box>

                    <FormLabel
                        ms="4px"
                        fontSize="md"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                    >
                        All Categories :
                    </FormLabel>
                    <div>
                        {!categoryLoading && !loading ? (
                            categories.map((item, i) => (
                                <MyButton
                                    key={i}
                                    item={item}
                                    detail={isDetail}
                                    click={addSelectedCategories}
                                    existed={existedCategories}
                                    type="category"
                                />
                            ))
                        ) : (
                            <Loading />
                        )}

                        {!categories.length && <p>There is no category.</p>}
                    </div>

                    <FormLabel
                        mt={3}
                        ms="4px"
                        fontSize="md"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                    >
                        All Tags:
                    </FormLabel>

                    <div>
                        {!tagLoading && !loading ? (
                            tags.map((item, i) => (
                                <MyButton
                                    key={i}
                                    item={item}
                                    detail={isDetail}
                                    click={addSelectedTags}
                                    existed={existedTags}
                                />
                            ))
                        ) : (
                            <Loading />
                        )}

                        {!tags.length && <p>There is no category.</p>}
                    </div>

                    {!isDetail && (
                        <Button
                            fontSize="sm"
                            variant="brand"
                            fontWeight="500"
                            mt={2}
                            mb="24px"
                            onClick={() => handleAdd()}
                        >
                            {isEdit ? "edit" : "Add"}
                        </Button>
                    )}
                </FormControl>
            )}
        </Box>
    );
}
