import React from "react";
import { Spinner, Flex } from "@chakra-ui/react";

export default function Loading() {
    return (
        <Flex
            align={{ sm: "flex-start", lg: "center" }}
            justify="center"
            w="100%"
            px="22px"
            py="18px"
        >
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
            />
        </Flex>
    );
}
