import Axios from "axios";
import Configs from "./configs";
import { getToken, removeToken } from "helpers";

export const Query = (data, success, err) => {
    let request = {};
    request.method = data.method ? data.method : "POST";
    request.url = Configs.API + data.url;
    const token = getToken();
    request.headers = data.logout
        ? undefined
        : {
              "x-access-token": token,
          };
    delete data.method;
    delete data.url;
    delete data.logout;
    request = { ...request, ...data };
    Axios(request)
        .then((response) => {
            typeof success === "function" && success(response.data);
        })
        .catch((error) => {
            if (error && error.response && error.response.status) {
                console.error(error);
                // if (error.response.status === 401) {
                //     removeToken();
                //     // window.location.reload();
                //     alert("4011");

                //     window.location.replace("/auth/sign-in");
                // }
            } else {
                console.log(error, "dddffffff");
            }

            typeof err === "function" && err(error);
        });
};
