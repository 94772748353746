import React, { useEffect, useState, useRef } from "react";

// Chakra imports
import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    Grid,
    Link,
    Text,
    useColorModeValue,
    SimpleGrid,
    IconButton,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

// Custom components

import HistoryItem from "views/admin/marketplace/components/HistoryItem";
import Card from "components/card/Card.js";
import { Query } from "helpers/query";
import Pagination from "components/pagination";
import { useQuery } from "helpers";
import Loading from "components/loading";
import { ChangeDate } from "helpers";
import { SmallAddIcon } from "@chakra-ui/icons";

import { MdSettings } from "react-icons/md";
import { isLogin } from "helpers";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    useToast,
    Tag,
    TagLabel,
    TagLeftIcon,
    TagRightIcon,
    TagCloseButton,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";

export default function Tags() {
    let query = useQuery();
    const history = useHistory();

    let pageStr = query.get("page");
    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const toast = useToast();
    const textColorDate = useColorModeValue("secondaryGray.600", "white");
    const initialRef = useRef(null);
    const finalRef = useRef(null);

    const [data, handleCategories] = useState([]);
    const [loading, handleLoading] = useState(false);
    const [page, handlePage] = useState(1);
    const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isEdit, handleEdit] = useState(false);
    const [item, handleItem] = useState(null);

    function getTags() {
        handleLoading(true);
        Query(
            {
                url: `tag/index?nocache=${new Date().getTime()}`,
                method: "GET",

                params: {
                    page: pageStr,
                },
            },
            (response) => {
                handleCategories(response.data);
                handlePage(response.data.page);
                handleLoading(false);
                console.log(response.data);
            }
        );
    }

    const onPageChanged = ({ selected: page }) => {
        handleLoading(true);
        const nextPage = page + 1;
        handlePage(nextPage);
        getTags(nextPage);
    };

    const save = () => {
        const titleValue = initialRef.current.value;
        Query(
            {
                url: `superAdmin/tag/store`,
                method: "POST",
                data: {
                    title: titleValue,
                    image: "test",
                    description: "test",
                },
            },
            (response) => {
                console.log(response);
                if (response.success) {
                    toast({
                        title: "Tag Added.",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                        position: "top-right",
                    });
                    onClose();
                    getTags();
                }
            }
        );
    };
    const hanldeDelete = (item) => {
        console.log(item);
        if (window.confirm("Are you sure to delete this tag?")) {
            Query(
                {
                    url: `superAdmin/tag/delete/${item._id}`,
                    method: "DELETE",
                },
                (response) => {
                    console.log(response);
                    if (response.success) {
                        toast({
                            title: "Tag Deleted.",
                            status: "success",
                            duration: 2000,
                            isClosable: true,
                            position: "top-right",
                        });
                        getTags();
                    }
                }
            );
        }
    };

    const hanldeEdit = (item) => {
        console.log(item);
        onOpen();
        handleEdit(true);
        handleItem(item);
    };

    const editTag = () => {
        const titleValue = initialRef.current.value;

        Query(
            {
                url: `superAdmin/tag/update/${item._id}`,
                method: "PATCH",
                data: {
                    title: titleValue,
                    image: "test",
                    description: "test",
                },
            },
            (response) => {
                console.log(response);
                if (response.success) {
                    toast({
                        title: "Tag Edited.",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                        position: "top-right",
                    });
                    onClose();
                    getTags();
                }
            }
        );
    };

    useEffect(() => {
        !pageStr && history.push("?page=1");
    }, []);

    useEffect(() => getTags(), [pageStr]);
    useEffect(() => {
        !isLogin() && history.push("/auth/sign-in");
    }, []);
    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            {/* Main Fields */}
            <Grid
                mb="20px"
                // gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
                gap={{ base: "20px", xl: "20px" }}
                display={{ base: "block", xl: "grid" }}
            >
                <Flex
                    flexDirection="column"
                    // gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
                >
                    {loading ? (
                        <Loading />
                    ) : (
                        <Card p="0px">
                            <Flex
                                align={{ sm: "flex-start", lg: "center" }}
                                justify="space-between"
                                w="100%"
                                px="22px"
                                py="18px"
                            >
                                <Text fontWeight="400" fontSize="18px" color={textColorDate}>
                                    Total : {data.total}
                                </Text>

                                <Button me="0" variant="outline" onClick={onOpen}>
                                    Add Tag
                                </Button>
                            </Flex>
                            <Box
                                align={{ sm: "flex-start", lg: "center" }}
                                w="100%"
                                px="22px"
                                py="18px"
                            >
                                {data.total
                                    ? data.items.map((item, i) => (
                                          <Tag
                                              size="lg"
                                              me="22px"
                                              my="16px"
                                              key={i}
                                              variant="outline"
                                              colorScheme="blue"
                                          >
                                              <TagLeftIcon
                                                  onClick={() => hanldeDelete(item)}
                                                  as={DeleteIcon}
                                                  style={{ cursor: "pointer" }}
                                              />
                                              <TagLabel mx="16px">{item.title}</TagLabel>
                                              <TagRightIcon
                                                  onClick={() => hanldeEdit(item)}
                                                  as={EditIcon}
                                                  style={{ cursor: "pointer" }}
                                              />
                                          </Tag>
                                      ))
                                    : "There is no tag."}
                            </Box>
                        </Card>
                    )}
                </Flex>
            </Grid>

            {data.totalPages > 1 && (
                <div className="mt-3 text-center">
                    <Pagination
                        totalPage={data.totalPages}
                        currrentPage={data.page}
                        size={data.limit}
                        onChangePage={onPageChanged}
                    />
                </div>
            )}

            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create new tag</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel> Title</FormLabel>
                            <Input
                                defaultValue={isEdit ? item.title : ""}
                                variant="auth"
                                ref={initialRef}
                                placeholder="Title"
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={isEdit ? editTag : save}>
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}
