import React, { useEffect, useState, useRef } from "react";

import { Box, SimpleGrid } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import ComplexTable from "views/admin/dataTables/components/ComplexTable";
import { columnsDataComplex } from "views/admin/dataTables/variables/columnsData";
import tableDataDevelopment from "views/admin/dataTables/variables/tableDataDevelopment.json";
import tableDataCheck from "views/admin/dataTables/variables/tableDataCheck.json";
import tableDataColumns from "views/admin/dataTables/variables/tableDataColumns.json";
import tableDataComplex from "views/admin/dataTables/variables/tableDataComplex.json";
import { Query } from "helpers/query";
import Pagination from "components/pagination";
import { useQuery } from "helpers";
import Loading from "components/loading";
import { isLogin } from "helpers";

export default function Posts() {
    let query = useQuery();
    const history = useHistory();

    let pageStr = query.get("page");
    const [data, handleCategories] = useState([]);
    const [loading, handleLoading] = useState(false);
    const [page, handlePage] = useState(1);
    function getTags() {
        handleLoading(true);
        Query(
            {
                url: `superAdmin/post/index`,

                method: "GET",

                params: {
                    page: pageStr,
                },
            },
            (response) => {
                handleCategories(response.data);
                handlePage(response.data.page);
                handleLoading(false);
                console.log(response.data, "yooo");
            },
            (error) => {
                console.log(error, "error is here");
                handleLoading(false);
            }
        );
    }

    useEffect(() => {
        !pageStr && history.push("?page=1");
    }, []);

    useEffect(() => getTags(), [pageStr]);
    useEffect(() => {
        !isLogin() && history.push("/auth/sign-in");
    }, []);
    const onPageChanged = ({ selected: page }) => {
        handleLoading(true);
        const nextPage = page + 1;
        handlePage(nextPage);
        getTags(nextPage);
    };
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
                {loading ? (
                    <Loading />
                ) : (
                    <ComplexTable
                        columnsData={columnsDataComplex}
                        tableData={data.items ? data.items : []}
                        count={data.total ? data.total : ""}
                        currrentPage={data.page}
                    />
                )}
            </SimpleGrid>
            {data.totalPages > 1 && (
                <div className="mt-3 text-center">
                    <Pagination
                        totalPage={data.totalPages}
                        currrentPage={data.page}
                        size={data.limit}
                        onChangePage={onPageChanged}
                    />
                </div>
            )}
        </Box>
    );
}
