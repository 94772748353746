import {
    Avatar,
    Box,
    Button,
    Flex,
    Progress,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    Badge,
    useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { Query } from "helpers/query";
import Pagination from "components/pagination";
import { useQuery } from "helpers";
import Loading from "components/loading";
import { useHistory } from "react-router-dom";
import {
    columnsDataDevelopment,
    columnsDataCheck,
    columnsDataColumns,
    columnsDataComplex,
} from "views/admin/dataTables/variables/columnsData";
function Users(props) {
    const { columnsData, tableData } = props;
    let query = useQuery();
    const history = useHistory();
    const [_page, handlePage] = useState(1);

    const [loading, handleLoading] = useState(true);
    const [users, handleUsers] = useState([]);
    const [total, handleTotal] = useState(0);
    const [totalPage, handleTotalPage] = useState(0);

    let pageStr = query.get("page");

    const columns = useMemo(() => columnsDataDevelopment, [columnsDataDevelopment]);

    const data = useMemo(() => users, [users]);

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy
    );

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = tableInstance;
    // initialState.pageSize = 21;

    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = useColorModeValue("secondaryGray.600", "white");

    function getUsers() {
        handleLoading(true);
        Query(
            {
                url: `superAdmin/user/index?page=${pageStr}`,
                method: "GET",
            },
            (response) => {
                handleUsers(response.data.items);
                handleTotal(response.data.total);
                handlePage(response.data.page);
                handleTotalPage(response.data.totalPages);
                handleLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    useEffect(() => {
        !pageStr && history.push("?page=1");
    }, []);
    useEffect(() => {
        getUsers();
    }, [pageStr]);

    const onPageChanged = ({ selected: ppage }) => {
        handleLoading(true);
        const nextPage = ppage + 1;
        handlePage(nextPage);
        getUsers(nextPage);
    };

    console.log(headerGroups, page);

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            <Flex direction="column" w="100%" overflowX={{ sm: "scroll", lg: "hidden" }}>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <Flex
                            align={{ sm: "flex-start", lg: "center" }}
                            justify="space-between"
                            w="100%"
                            px="22px"
                            pb="20px"
                            mb="10px"
                            boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
                        >
                            <Text color={textColor} fontSize="xl" fontWeight="600">
                                USERS: {total}
                            </Text>
                            <Button variant="action">See all</Button>
                        </Flex>
                        <Table {...getTableProps()} variant="simple" color="gray.500" pageSize="21">
                            <Thead>
                                {headerGroups.map((headerGroup, index) => (
                                    <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                        {headerGroup.headers.map((column, index) => (
                                            <Th
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps()
                                                )}
                                                pe="10px"
                                                key={index}
                                                borderColor="transparent"
                                            >
                                                <Flex
                                                    justify="space-between"
                                                    align="center"
                                                    fontSize={{ sm: "10px", lg: "12px" }}
                                                    color="gray.400"
                                                >
                                                    {column.render("Header")}
                                                </Flex>
                                            </Th>
                                        ))}
                                    </Tr>
                                ))}
                            </Thead>

                            <Tbody {...getTableBodyProps()}>
                                {page.map((row, index) => {
                                    prepareRow(row);
                                    return (
                                        <Tr {...row.getRowProps()} key={index}>
                                            {row.cells.map((cell, index) => {
                                                let data = "";
                                                if (
                                                    cell.column.Header === "firstName" ||
                                                    cell.column.Header === "lastName" ||
                                                    cell.column.Header === "email"
                                                ) {
                                                    data = (
                                                        <Flex align="center">
                                                            <Text
                                                                color={textColor}
                                                                fontSize="sm"
                                                                fontWeight="600"
                                                            >
                                                                {cell.value}
                                                            </Text>
                                                        </Flex>
                                                    );
                                                } else if (cell.column.Header === "type") {
                                                    data = (
                                                        <Badge
                                                            color={textColor}
                                                            fontSize="sm"
                                                            fontWeight="700"
                                                            mt={1}
                                                        >
                                                            {cell.value}
                                                        </Badge>
                                                    );
                                                }

                                                return (
                                                    <Td
                                                        {...cell.getCellProps()}
                                                        key={index}
                                                        fontSize={{ sm: "14px" }}
                                                        minW={{
                                                            sm: "150px",
                                                            md: "200px",
                                                            lg: "auto",
                                                        }}
                                                        borderColor="transparent"
                                                    >
                                                        {data}
                                                    </Td>
                                                );
                                            })}
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>

                        {totalPage > 1 && (
                            <div className="mt-3 text-center">
                                <Pagination
                                    totalPage={totalPage}
                                    currrentPage={_page}
                                    size="21"
                                    onChangePage={onPageChanged}
                                />
                            </div>
                        )}
                    </>
                )}
            </Flex>
        </Box>
    );
}

export default Users;
