import React from "react";

import { Icon } from "@chakra-ui/react";
import {
    MdBarChart,
    MdPerson,
    MdHome,
    MdLock,
    MdOutlineShoppingCart,
    MdOutlinePeople,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Users from "views/admin/users";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import Posts from "views/admin/posts";
import EditPost from "views/admin/posts/EditPost";
import RTL from "views/admin/rtl";
import Categories from "views/admin/categories";
import EditCategory from "views/admin/categories/EditCategory";
// Auth Imports
import SignInCentered from "views/auth/signIn";
import Tags from "views/admin/tags";
const routes = [
    // {
    //     name: "Main Dashboard",
    //     layout: "/admin",
    //     path: "/default",
    //     icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    //     component: MainDashboard,
    //     show: true,
    // },
    {
        name: "Users",
        layout: "/admin",
        path: "/users",
        icon: <Icon as={MdOutlinePeople} width="20px" height="20px" color="inherit" />,
        component: Users,
        secondary: true,
        show: true,
    },
    {
        name: "Categories",
        layout: "/admin",
        path: "/categories",
        icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit" />,
        component: Categories,
        show: true,
    },
    {
        name: "Edit Category",
        layout: "/admin",
        path: `/category/edit/:CATEGORY_ID`,
        icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit" />,
        component: EditCategory,
        show: false,
    },
    {
        name: "Add Category",
        layout: "/admin",
        path: `/category/add`,
        icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit" />,
        component: EditCategory,
        show: false,
    },
    {
        name: "Posts",
        layout: "/admin",
        icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
        path: "/posts",
        component: Posts,
        show: true,
    },
    {
        name: "Add Post",
        layout: "/admin",
        path: `/post/add`,
        icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit" />,
        component: EditPost,
        show: false,
    },
    {
        name: "Edit Posttttt",
        layout: "/admin",
        path: `/post/edit/:POST_ID`,
        icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit" />,
        component: EditPost,
        show: false,
    },
    {
        name: "Detail Postttt",
        layout: "/admin",
        path: `/post/detail/:POST_ID`,
        icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit" />,
        component: EditPost,
        show: false,
    },
    {
        name: "Tags",
        layout: "/admin",
        path: `/tags`,
        icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit" />,
        component: Tags,
        show: true,
    },

    {
        name: "Profile",
        layout: "/admin",
        path: "/profile",
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
        component: Profile,
        show: true,
    },
    {
        name: "Sign In",
        layout: "/auth",
        path: "/sign-in",
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
        component: SignInCentered,
        show: true,
    },
    {
        name: "RTL Admin",
        layout: "/rtl",
        path: "/rtl-default",
        icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
        component: RTL,
        show: true,
    },
];

export default routes;
