import React, { useEffect, useState } from "react";

// Chakra imports
import {
    Box,
    Button,
    Flex,
    Grid,
    Link,
    Text,
    useColorModeValue,
    SimpleGrid,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

// Custom components

import HistoryItem from "views/admin/marketplace/components/HistoryItem";
import Card from "components/card/Card.js";
import { Query } from "helpers/query";
import Pagination from "components/pagination";
import { useQuery } from "helpers";
import Loading from "components/loading";
import { ChangeDate } from "helpers";
import { SmallAddIcon } from "@chakra-ui/icons";
import { isLogin } from "helpers";

export default function Categories() {
    let query = useQuery();
    const history = useHistory();

    let pageStr = query.get("page");
    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");

    const [data, handleCategories] = useState([]);
    const [loading, handleLoading] = useState(false);
    const [page, handlePage] = useState(1);
    const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");

    console.log(pageStr, "ppp");

    function getCategories() {
        handleLoading(true);
        Query(
            {
                url: `category/index?nocache=${new Date().getTime()}`,
                method: "GET",

                params: {
                    page: pageStr,
                },
            },
            (response) => {
                handleCategories(response.data);
                handlePage(response.data.page);
                handleLoading(false);
                console.log(response.data);
            }
        );
    }

    const onPageChanged = ({ selected: page }) => {
        handleLoading(true);
        const nextPage = page + 1;
        handlePage(nextPage);
        getCategories(nextPage);
    };
    const textColorDate = useColorModeValue("secondaryGray.600", "white");

    const handleDelete = () => {
        handleLoading(true);
        getCategories();
    };

    useEffect(() => {
        !pageStr && history.push("?page=1");
    }, []);

    useEffect(() => {
        !isLogin() && history.push("/auth/sign-in");
    }, []);

    useEffect(() => getCategories(), []);
    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            {/* Main Fields */}
            <Grid
                mb="20px"
                // gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
                gap={{ base: "20px", xl: "20px" }}
                display={{ base: "block", xl: "grid" }}
            >
                <Flex
                    flexDirection="column"
                    // gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
                >
                    {loading ? (
                        <Loading />
                    ) : (
                        <Card p="0px">
                            <Flex
                                align={{ sm: "flex-start", lg: "center" }}
                                justify="space-between"
                                w="100%"
                                px="22px"
                                py="18px"
                            >
                                <Text fontWeight="400" fontSize="18px" color={textColorDate}>
                                    Total : {data.total}
                                </Text>

                                <Button
                                    me="0"
                                    variant="outline"
                                    onClick={() => history.push(`/admin/category/add`)}
                                >
                                    Add Category
                                    {/* <SmallAddIcon /> */}
                                </Button>
                            </Flex>
                            {data.total &&
                                data.items.map((item, i) => (
                                    <HistoryItem
                                        name={item.title}
                                        author="tedad postha 130"
                                        date={ChangeDate(item.createdAt)}
                                        image={item.image}
                                        price={item.description}
                                        id={item._id}
                                        custom={(row, el, index) =>
                                            (page - 1) * data.limit + (i + 1)
                                        }
                                        type="category"
                                        repeatLoading={handleDelete}
                                    />
                                ))}
                        </Card>
                    )}
                </Flex>
            </Grid>

            {data.totalPages > 1 && (
                <div className="mt-3 text-center">
                    <Pagination
                        totalPage={data.totalPages}
                        currrentPage={data.page}
                        size={data.limit}
                        onChangePage={onPageChanged}
                    />
                </div>
            )}
        </Box>
    );
}
