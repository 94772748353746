import React from "react";

import { useHistory } from "react-router-dom";
// import { scrollToTop } from "../../helper";
// import "./../../styles/Pagination.scss";
import { Flex, Text } from "@chakra-ui/react";
import { scrollToTop } from "helpers";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

export default function Pagination({ totalPage, currrentPage }) {
    let arr = Array.from(Array(totalPage).keys());
    const history = useHistory();
    let queryStr = history.location.search;

    const handleClick = (page) => {
        console.log("yooo clickkk");
        let newQueryStr = queryStr.replace(`page=${currrentPage}`, `page=${page}`);

        history.push({
            pathname: history.location.pathname,
            search: newQueryStr,
        });
        scrollToTop();
    };

    const handlePrevious = () => {
        history.push({
            pathname: history.location.pathname,
            search: queryStr.replace(`page=${currrentPage}`, `page=${Number(currrentPage) - 1}`),
        });
        scrollToTop();
    };

    const handleNext = () => {
        history.push({
            pathname: history.location.pathname,
            search: queryStr.replace(`page=${currrentPage}`, `page=${Number(currrentPage) + 1}`),
        });
        scrollToTop();
    };

    return (
        <Flex>
            <button
                className="pageBtn"
                onClick={() => handlePrevious()}
                style={{
                    backgroundColor: Number(currrentPage) === 1 ? "#E4EBE9" : "#111c44",
                    color: Number(currrentPage) === 1 ? "#CACACA" : "white",
                    borderRadius: "5px",
                    fontSize: "14px",
                    padding: "0 10px",
                }}
                disabled={Number(currrentPage) === 1}
            >
                <ChevronLeftIcon />
            </button>

            {arr.map((item, i) => (
                <div key={i}>
                    <Text
                        color="secondaryGray.600"
                        fontSize={{
                            base: "sm",
                        }}
                        fontWeight="400"
                        mx="10px"
                        style={{
                            backgroundColor: Number(currrentPage) === item + 1 && "#111c44",
                            color: Number(currrentPage) === item + 1 && "white",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                        px="10px"
                        py="10px"
                        onClick={() => handleClick(item + 1)}
                    >
                        {item + 1}
                    </Text>
                </div>
            ))}

            <button
                className="pageBtn"
                disabled={Number(currrentPage) === totalPage}
                onClick={() => handleNext()}
                style={{
                    backgroundColor: Number(currrentPage) === totalPage ? "#E4EBE9" : "#111c44",
                    color: Number(currrentPage) === totalPage ? "#CACACA" : "white",
                    borderRadius: "5px",
                    fontSize: "14px",
                    padding: "0 10px",
                }}
            >
                <ChevronRightIcon />
            </button>
        </Flex>
    );
}
